let toggleJS;
toggleJS        = function () {

    const toggleArea   = $('.js-toggle-box');
    const toggleButton = $('.js-toggle-btn');

    const init = function () {
        toggleButton.on('click', function () {
            toggleArea.toggleClass('is-active');
            toggleButton.toggleClass('is-active');
        });
    };

    this.run = function () {
        init();
    };

    this.resize = function () {

    };

    this.load = function () {

    };

};
const __toggleJS = new toggleJS();
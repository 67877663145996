let productListJS;
productListJS         = function () {

    const clickEl = $('.js-show-product-detail');

    const click = function () {
        clickEl.on('click', function (e) {
            if (window.innerWidth > window_lg) {
                e.preventDefault();
                clone($(this));
                remove();
            }
        })
    };

    const clone = function (el) {
        const item      = el.parents('.product-list__item');
        const cloneHtml = item.clone();

        $(cloneHtml).find('.goto-page').remove();
        $(cloneHtml).addClass('product-list__item--active');
        $(cloneHtml).css({
            top: item.position().top,
            left: item.position().left,
            width: $('.product-list').width(),
            opacity: 0
        });
        $(cloneHtml).find('.product-list__image-box').css({
            minWidth: item.width()
        });

        $('.product-list__item--active').remove();
        item.parent().append(cloneHtml);
        setTimeout(function () {
            $('.product-list__item--active').css({
                left: 0,
                opacity: 1
            })
        });

        $('.product-list__item--active').mouseleave(function () {
            $(this).css({
                top: item.position().top,
                left: item.position().left,
                width: $('.product-list').width(),
                opacity: 0
            });
            setTimeout(function () {
                $('.product-list__item--active').remove();
            }, 300);
        })
    };

    const remove = function () {

    };

    this.run = function () {
        click();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __productListJS = new productListJS();

let homePromoJS;
homePromoJS = function () {

    this.run = function () {
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __homePromoJS = new homePromoJS();
$(document).ready(function () {
    __siteJS.run();
    __toggleJS.run();
    __stackedLabelJS.run();
    __mobileMenuJS.run();
    __flickityCustomNavJS.run();
    __homeActivityJS.run();
    __productListJS.run();
    __desktopMenuJS.run();
});

$(window).on('load', function () {
    __siteJS.load();
});

$(window).on('resize', function () {
    __siteJS.resize();
});

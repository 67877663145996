let mobileMenuJS;
mobileMenuJS         = function () {

    const body = $('body');
    const btn  = $('.js-mobile-menu-btn');
    const menu = $('.js-mobile-menu');

    const init = function () {
        btn.on('click', function () {
            if ($(this).hasClass('is-active')) {
                hide();
            } else {
                show($(this).attr('data-menu'));
            }
        });
    };

    const show = function (menu_id) {
        body.addClass('has-mobile-menu');
        btn.addClass('is-active');
        menu.addClass('is-active');

        $('[data-menu]').removeClass('is-active');
        $('[data-menu="'+ menu_id +'"]').addClass('is-active');
    };

    const hide = function () {
        body.removeClass('has-mobile-menu');
        btn.removeClass('is-active');
        menu.removeClass('is-active');
        $('[data-menu]').removeClass('is-active');
    };

    this.run = function () {
        init();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __mobileMenuJS = new mobileMenuJS();

let flickityCustomNavJS;
flickityCustomNavJS = function () {

    const arrow = function () {
        const prev = $('[data-js-flickity-prev-btn]');
        const next = $('[data-js-flickity-next-btn]');

        prev.on('click', function () {
            $('[data-flickity-id="'+ $(this).attr('data-js-flickity-prev-btn') +'"]').flickity('previous');
        });

        next.on('click', function () {
            $('[data-flickity-id="'+ $(this).attr('data-js-flickity-next-btn') +'"]').flickity('next');
        });
    };

    const changeSameSlide = function () {
        $('[data-flickity-id]').on( 'change.flickity', function( event, index ) {
            $('[data-flickity-id="'+ $(event['currentTarget']).attr('data-flickity-id') +'"]').flickity( 'select', index );
        });
    };

    this.run = function () {
        arrow();
        changeSameSlide();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __flickityCustomNavJS = new flickityCustomNavJS();

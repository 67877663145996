let stackedLabelJS;
stackedLabelJS = function () {

    this.run = function () {
        $('.form-group-stacked').find('.form-control').focusout(function () {
            if ($(this).val().length > 0) {
                $(this).parents('.form-group-stacked').addClass('is-stacked');
            } else {
                $(this).parents('.form-group-stacked').removeClass('is-stacked');
            }
        })
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __stackedLabelJS = new stackedLabelJS();
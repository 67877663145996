let SiteJS;
let window_width = $(window).width();
let window_sm    = 576;
let window_md    = 768;
let window_lg    = 992;
let window_xl    = 1200;

SiteJS         = function () {

    const plugins = function () {
        $(':input').inputmask();
        $('.js-nice-scroll').niceScroll({
            cursorcolor: '#d8d8d8',
            cursorborder: 'none',
            cursorwidth: '4px',
            cursoropacitymax: 0.2,
            railpadding: {
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
            }
        });
        AOS.init();
    };

    const addBgHeader = function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 50) {
                $('.site-head__block--left').removeClass('site-head__block--no-bg');
            } else {
                $('.site-head__block--left').addClass('site-head__block--no-bg');
            }
        });
    };

    this.run = function () {
        plugins();
        addBgHeader();
    };

    this.resize = function () {
    };

    this.load = function () {

    };

};
const __siteJS = new SiteJS();



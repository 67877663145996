let homeActivityJS;
homeActivityJS         = function () {

    const carousel = $('.home-activity__list');
    const image    = $('.js-carousel-item-image');
    const order    = $('.js-carousel-item-order');
    const select   = $('.js-carousel-item-select');

    const init = function () {
        carousel.flickity({
            cellAlign: "left", contain: true, prevNextButtons: false, pageDots: false, imagesLoaded: true
        });
    };

    const ready = function () {
        setImage(carousel.find('.is-selected'));
    };

    const change = function () {
        carousel.on('change.flickity', function (event, index) {
            setImage(carousel.find('.is-selected'));
        });
    };

    const setImage = function (item) {

        image.removeClass('is-selected');
        image.eq(item.index()).addClass('is-selected');

        select.removeClass('is-selected');
        select.eq(item.index()).addClass('is-selected');

        order.text((item.index() > 8 ? '' : '0') + (item.index() + 1));
    };

    const click = function () {
        select.on('click', function () {
            carousel.flickity('select', $(this).index());
        })
    };

    this.run = function () {
        init();
        ready();
        change();
        click();
    };

    this.resize = function () {
    };

    this.load = function () {
    };

};
const __homeActivityJS = new homeActivityJS();
